import React, { Component } from 'react';

const initData = {
    heading: "Testimonials",
    headingText_1: "Here’s what our clients say about us!",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const data = [
    {
        id: 1,
        quote: "/img/quote.png",
        avatar: "/img/avatar_1.png",
        reviewer: "Junaid Hasan",
        post: "CEO, Themeland",
        text: "Manhill Advertising is the most professional and effective Ad agency I’ve ever had the pleasure of working with! Oh, and by the way, they’ve helped boost my sales by over 60% over the past 2 years!"
    },
    {
        id: 2,
        quote: "/img/quote.png",
        avatar: "/img/avatar_2.png",
        reviewer: "Yasmin Akter",
        post: "Founder, Themeland",
        text: "– If you’re looking for marketing services that actually get you results, look no further than Manhill Advertising. I just wish I had found them sooner! My pottery start-up wouldn’t have taken off without their help."
    },
    {
        id: 3,
        quote: "/img/quote.png",
        avatar: "/img/avatar_3.png",
        reviewer: "Arham Abbasi",
        post: "CEO, Themeland",
        text: "Truly the best advertising and marketing agency of Pakistan! As an older gentleman, I used to struggle with the complexity of today’s digital landscape. Thankfully, I approached the right agency for help, as Manhill walked me through every single step of the digital marketing strategy they developed for my business. "
    },
    {
        id: 4,
        quote: "/img/quote.png",
        avatar: "/img/avatar_3.png",
        reviewer: "Ahmed Memon",
        post: "CEO, Themeland",
        text: "Unmatched offerings at unbelievable price points! Manhill’s advertising and marketing services are almost too good to be true. I recommend their services to all my business associates."
    },
    {
        id: 5,
        quote: "/img/quote.png",
        avatar: "/img/avatar_3.png",
        reviewer: "Hafsa Khan",
        post: "CEO, Themeland",
        text: "I didn’t know that Digital Marketing could be so multi-faceted and intricate. Manhill’s team has helped me grow my web traffic by over 200% in 6 months through their expertise and in-depth knowledge of the latest trends."
    }
]

class ReviewOne extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section id="review" className="section review-area bg-overlay ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-white" style={{textTransform:'capitalize'}}>{this.state.initData.heading}</h2>
                                <p className="text-white d-none d-sm-block mt-4">{this.state.initData.headingText_1}</p>
                                {/* <p className="text-white d-block d-sm-none mt-4">{this.state.initData.headingText_2}</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {/* Client Reviews */}
                        <div className="client-reviews owl-carousel">
                            {/* Single Review */}
                            {this.state.data.map((item, idx) => {
                                return(
                                    <div key={`ro_${idx}`} className="single-review p-5">
                                        {/* Review Content */}
                                        <div className="review-content">
                                            {/* Review Text */}
                                            <div className="review-text">
                                                <p>{item.text}</p>
                                            </div>
                                            {/* Quotation Icon */}
                                            <div className="quot-icon">
                                                <img className="avatar-sm" src={item.quote} alt="" />
                                            </div>
                                        </div>
                                        {/* Reviewer */}
                                        <div className="reviewer media mt-3">
                                            {/* Reviewer Thumb */}
                                            <div className="reviewer-thumb">
                                                <img className="avatar-lg radius-100" src={item.avatar} alt="" />
                                            </div>
                                            {/* Reviewer Media */}
                                            <div className="reviewer-meta media-body align-self-center ml-4">
                                                <h5 className="reviewer-name color-primary mb-2">{item.reviewer}</h5>
                                                {/* <h6 className="text-secondary fw-6">{item.post}</h6> */}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ReviewOne;