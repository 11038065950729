import React, { Component } from 'react';

const initData = {
    heading: "We’re not your average advertising agency. We don’t leave you guessing, wondering or trying to figure it all out on your own. Every campaign is customized based on the unique needs of the client. Reach out to us today to take your business to the next level!",
    headingText_1: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingText_2: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
    btnText: "Contact Us"
}

class Cta extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section className="section cta-area bg-overlay ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            {/* Section Heading */}
                            <div className="section-heading text-center m-0">
                                <h3 className="text-white">{this.state.data.heading}</h3>
                                {/* <p className="text-white d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                <p className="text-white d-block d-sm-none mt-4">{this.state.data.headingText_2}</p> */}
                                <a href="/contact" className="btn btn-bordered-white mt-4">{this.state.data.btnText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Cta;