import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";

class HeaderOne extends Component {
    render() {
        return (
            <header id="header">
                {/* Navbar */}
                <nav className="navbar navbar-expand">
                    <div className="container header">

                        <Link to="/" key="0" className="navbar-brand" >
                            <img className="navbar-brand-regular" src={this.props.imageData} alt="brand-logo" style={{ width: "160px" }} />
                            <img className="navbar-brand-sticky" src="/img/logo.webp" alt="sticky brand-logo" style={{ width: "100px" }} />
                        </Link>

                        <div className="ml-auto" />
                        {/* Navbar */}
                        <ul className="navbar-nav items">
                            <li className="nav-item">
                                <Link to="/" key="1" className="nav-link">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" key="2" className="nav-link">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/clients" key="3" className="nav-link">Our Clients</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/portfolio" key="4" className="nav-link">Portfolio</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/credibility" key="5" className="nav-link">Credibility</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" key="7" className="nav-link">Contact</Link>
                            </li>
                        </ul>
                        {/* Navbar Icons */}
                        <ul className="navbar-nav icons">
                            <li className="nav-item social">
                                <a href="https://www.facebook.com/ManhillAdvertisingPakistan" className="nav-link"><i className="fab fa-facebook-f" /></a>
                            </li>
                            <li className="nav-item social">
                                <a href="https://twitter.com/manhill_adv" className="nav-link"><i className="fab fa-twitter" /></a>
                            </li>
                        </ul>
                        {/* Navbar Toggler */}
                        <ul className="navbar-nav toggle">
                            <li className="nav-item">
                                <a href="/#" className="nav-link" data-toggle="modal" data-target="#menu">
                                    <i className="fas fa-bars toggle-icon m-0" />
                                </a>
                            </li>
                        </ul>
                        {/* Navbar Action Button */}

                    </div>
                </nav>
            </header>
        );
    }
}

export default HeaderOne;