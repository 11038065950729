import React, { Component } from 'react';

const initData = {
    image_1: "/img/404.svg",
    image_2: "/img/rocket.svg",
    image_3: "/img/earth.svg",
    image_4: "/img/moon.svg",
    image_5: "/img/astronaut.svg",
    btnText: "GO BACK HOME"
}

class Error extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <div className="error-page">
                <div className="error-area stars">
                    {/* Error Content */}
                    <div className="error-content d-flex flex-column justify-content-center align-items-center text-center">
                        <img className="error-thumb" src={this.state.data.image_1} alt="" />
                        <a href="/" className="btn btn-bordered-white mt-4">{this.state.data.btnText}</a>
                    </div>
                    {/* Error Objects */}
                    <div className="error-objects">
                        <img className="thumb-1" src={this.state.data.image_2} alt="" />
                        {/* Earth Moon */}
                        <div className="earth-moon">
                            <img className="thumb-2" src={this.state.data.image_3} alt="" />
                            <img className="thumb-3" src={this.state.data.image_4} alt="" />
                        </div>
                        {/* Astronaut */}
                        <div className="astronaut">
                            <img className="thumb-4" src={this.state.data.image_5} alt="" />
                        </div>
                    </div>
                    {/* Glowing Stars */}
                    <div className="glowing-stars">
                        <div className="star" />
                        <div className="star" />
                        <div className="star" />
                        <div className="star" />
                        <div className="star" />
                    </div>
                </div>
            </div>
        );
    }
}

export default Error;