import React, { Component } from 'react';

import ScrollupSection from '../../Scrollup/Scrollup';
import Header from '../../Header/HeaderOne';
import BreadcrumbSection from '../../Breadcrumb/BreadcrumbOne';
import ServiceSection from '../../Services/ServiceOne';
import ReviewSection from '../../Reviews/ReviewOne';
import ContactSection from '../../Contact/ContactOne';
import CtaSection from '../../Cta/Cta';
import FooterSection from '../../Footer/FooterOne';
import ModalSearchSection from '../../Modal/ModalSearch';
import ModalMenuSection from '../../Modal/ModalMenu';

class Services extends Component {
    render() {
        return (
            <div>
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <BreadcrumbSection heading={"What We Offer"} home={"Home"} page={"Pages"} title={"Our Services"} />
                    <ServiceSection />
                    <ReviewSection />
                    <ContactSection />
                    <CtaSection />
                    <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default Services;