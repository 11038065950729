import React, { Component } from 'react';
import './portfolio.css'



// const imgs = {
//     social_image_1: "/img/portfolio/social-1.jpg",
//     social_image_2: "/img/portfolio/social-2.jpg",
//     social_image_3: "/img/portfolio/social-3.jpg",
//     social_image_4: "/img/portfolio/social-4.jpg",
//     social_image_5: "/img/portfolio/social-5.jpg",
//     social_image_6: "/img/portfolio/social-6.jpg",
//     social_image_7: "/img/portfolio/social-7.jpg",
//     social_title_1: "Green Heights Kalam",
//     social_title_2: "Aziz Skyline Apartments",
//     social_title_3: "Downtown Mall",
//     social_title_4: "Swiss International Resort",
//     social_title_5: "Aman Golf View",
//     social_title_6: "Mall of Gulgasht",
//     social_title_7: "Grand Atrium",
//     gov_image_1: "/img/portfolio/gov-1.png",
//     gov_image_2: "/img/portfolio/gov-2.png",
//     gov_image_3: "/img/portfolio/gov-3.png",
//     gov_image_4: "/img/portfolio/gov-4.png",
//     gov_title_1: "Gwadar Port",
//     gov_title_2: "Government of Sindh",
//     gov_title_3: "Government of Sindh",
//     gov_title_4: "Directorate of Public Relations Balochistan",
//     print_image_1: "/img/portfolio/print-1.jpg",
//     print_image_2: "/img/portfolio/print-2.jpg",
//     print_image_3: "/img/portfolio/print-3.jpg",
//     print_image_4: "/img/portfolio/print-4.jpg",
//     print_text_1: "Gwadar Commercial Zone",
//     print_text_2: "Star Avenue Rabia Residency",
//     print_text_3: "Mall VIII - Mumtaz City",
//     print_text_4: "Star Twin Tower",
//     text: "Lorem ipsum dolor sit amet, consectet ur adipisicing elit.",
//     btnText: "View More"
// }

const imgs = [
    { author: "", tag: "Government Sector", src: "/img/portfolio/gov-1.png" },
    { author: "", tag: "Government Sector", src: "/img/portfolio/gov-2.png" },
    { author: "", tag: "Government Sector", src: "/img/portfolio/gov-3.png" },
    { author: "", tag: "Print Media", src: "/img/portfolio/print-1.jpg" },
    { author: "", tag: "Print Media", src: "/img/portfolio/print-2.jpg" },
    { author: "", tag: "Print Media", src: "/img/portfolio/print-3.jpg" },
    { author: "", tag: "Social Media", src: "/img/portfolio/social-1.jpg" },
    { author: "", tag: "Social Media", src: "/img/portfolio/social-2.jpg" }
];

const filters = [
    { name: "Government Sector", status: false },
    { name: "Print Media", status: false },
    { name: "Social Media", status: false },
];

const Filters = ({ onClickAll, all, onClick, filters }) =>
    <form id="formPortfolio">
        <ul>
            <li onClick={onClickAll}>
                <input
                    type="checkbox"
                    checked={all}
                />
                <label htmlFor="all">All</label>
            </li>
            {filters.map(
                (filter, i) =>
                    <li key={i} data-index={i} onClick={onClick} >
                        <input
                            id={filter.name}
                            type="checkbox"
                            checked={filter.status}
                        />
                        <label htmlFor={filter.name}>{filter.name}</label>
                    </li>)}
        </ul>
    </form>

const Cards = ({ imgs }) =>
    <div id="cards">
        <ul>
            {imgs.map(
                (img, i) =>
                    <li key={i}>
                        <figure>
                            <img src={img.src} alt={img.author} />
                            <figcaption>
                                <div>{img.author} </div>
                                <span>{img.tag}</span>
                            </figcaption>
                        </figure>
                    </li>)}
        </ul>
    </div>

class PortfolioSix extends Component {

    state = {
        imgs,
        filters,
        all: true
    };

    setFilter = (e) => {
        e.preventDefault();
        const { filters, all } = this.state;
        const { index } = e.currentTarget.dataset;

        filters[index].status = !filters[index].status;
        this.setState({
            filters
        });

        this.updateFilters();
        this.updateImgs();
    }

    setAll = () => {
        const { filters } = this.state;

        filters.forEach(
            filter => {
                filter.status = false;
            }
        );

        this.setState({
            all: true,
            filters
        });
    }

    updateFilters() {
        const allFiltersTrue = filters.every(filter => filter.status === true);
        const allFiltersFalse = filters.every(filter => filter.status === false);

        if (allFiltersTrue || allFiltersFalse) {
            this.setAll();
        } else {
            this.setState({
                all: false
            });
        }
    }

    updateImgs() {
        const { filters, all } = this.state;
        let newImgs = [];
        let a = 0;

        imgs.forEach((img, imgKey) => {
            filters.forEach((filter, filterKey) => {
                if ((img.tag == filter.name) && (filter.status == true)) {
                    newImgs[a] = img;
                    a++;
                }
            })
        });

        this.setState({
            imgs: newImgs
        });
    }

    render() {
        const { filters, all } = this.state;
        return (
            <div>
                <Filters
                    onClickAll={this.setAll}
                    all={all}
                    onClick={this.setFilter}
                    filters={filters} />
                {(all) ? (
                    <Cards imgs={imgs} />
                ) : (
                    <Cards imgs={this.state.imgs} />
                )}
              
            </div>
        );
    }
}

export default PortfolioSix;