import React, { useRef, Component } from 'react';
import axios from 'axios';
import emailjs from 'emailjs-com';

function ContactOne(props) {

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_8fmg6kj', 'template_q58mrep', form.current, 'user_yX7TEHctQWviqtfYWsH7B')
            .then((result) => {
                //  console.log(result.text);
                alert('Request Sent Successfully. We will get back to you soon')
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <section id="contact" className="contact-area ptb_100">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-lg-5">
                        {/* Section Heading */}
                        <div className="section-heading text-center mb-3">
                            <h2 style={{ textTransform: 'capitalize' }}>Reach Us Now</h2>
                            <p className="d-none d-sm-block mt-4">41-U, Dr. Mahmood Hussain Road, Block 6, P.E.C.H.S., Karachi.</p>

                        </div>
                        {/* Contact Us */}


                        <div className="contact-us">
                            <ul>
                                {/* Contact Info */}


                                <li className="contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3">
                                    <span><i className="fas fa-mobile-alt fa-3x" /></span>
                                    <a className="d-block my-2" href="/#">
                                        <h4>021-34540764-7</h4>
                                    </a>
                                    {/* <p>DFDF</p> */}
                                </li>

                                <li className="contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3">
                                    <span><i className="fas fa-envelope-open-text fa-3x" /></span>
                                    <a className="d-block my-2" href="/#">
                                        <h4>info@manhilladvertising.com</h4>
                                        <br />
                                        <h4>manhilladvertising@gmail.com</h4>
                                    </a>
                                    {/* <p>DFDF</p> */}
                                </li>


                            </ul>
                        </div>


                    </div>
                    <div className="col-12 col-lg-6 pt-4 pt-lg-0">
                        {/* Contact Box */}
                        <div className="contact-box text-center">
                            {/* Contact Form */}
                            <form ref={form} id="contact-form" onSubmit={sendEmail}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="name" placeholder="Name" required="required" />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="company" placeholder="Company Name" required="required" />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control" name="email" placeholder="Email" required="required" />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="phone" placeholder="Phone" required="required" />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea className="form-control" name="message" placeholder="Message" required="required" defaultValue={""} />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-bordered active btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>Submit</button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}




export default ContactOne;