import React, { Component } from 'react';
import axios from 'axios';



const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json/themeOneContentSection";



class ContentOne extends Component {
    state = {
        data: {},
        contentData: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    contentData: res.data.contentData
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section content-area bg-grey ptb_150">
                {/* Shape Top */}
                <div className="shape shape-top">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                        <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                            {/* Content Inner */}
                            <div className="content-inner text-center">
                                {/* Section Heading */}
                                <div className="section-heading text-center mb-3">
                                    <h2 style={{ textTransform: 'capitalize', fontSize: '30px', letterSpacing: '0px' }}>Manhill Advertising is the answer to all your advertising needs</h2>
                                    <p className="d-none d-sm-block mt-4">Here's what we can help you with</p>
                                    {/* <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p> */}
                                </div>
                                {/* Content List */}
                                <ul className="content-list text-left">
                                    {/* Single Content List */}
                                    {this.state.contentData.map((item, idx) => {
                                        // return(
                                        //     <li key={`co_${idx}`} className="single-content-list media py-2">
                                        //         <div className="content-icon pr-4">
                                        //             <span className="color-1"><i className={item.iconClass} /></span>
                                        //         </div>
                                        //         <div className="content-text media-body">
                                        //             <span><b>{item.title}</b><br />{item.text}</span>
                                        //         </div>
                                        //     </li>
                                        // );
                                    })}
                                    <li className="single-content-list media py-2">
                                        <div className="content-icon pr-4">
                                            <span className="color-1"><i className="fas fa-angle-double-right" /></span>
                                        </div>
                                        <div className="content-text media-body">
                                            <span><b style={{fontWeight:'bold', color:'black'}}>Create an Online Presence</b><br />In today’s digital world, your brand’s online presence is just as important as its reputation in the real world. Tell your story to the world through our flagship omni-channel digital marketing strategies!</span>
                                        </div>
                                    </li>
                                    <li className="single-content-list media py-2">
                                        <div className="content-icon pr-4">
                                            <span className="color-1"><i className="fas fa-angle-double-right" /></span>
                                        </div>
                                        <div className="content-text media-body">
                                            <span><b style={{fontWeight:'bold', color:'black'}}>Develop a Comprehensive Marketing Strategy</b><br />When it comes to marketing your brand, we believe the best thing you can do is plan for the future. Our team of experts are up to date with the latest trends in digital and traditional marketing, and we can help you future-proof your investment and maximize ROI!</span>
                                        </div>
                                    </li>
                                    <li className="single-content-list media py-2">
                                        <div className="content-icon pr-4">
                                            <span className="color-1"><i className="fas fa-angle-double-right" /></span>
                                        </div>
                                        <div className="content-text media-body">
                                            <span><b style={{fontWeight:'bold', color:'black'}}>Turbo-charge Your Sales</b><br />Our unique and innovative style of marketing helps clients maximize lead generation and drive conversions. What this means? More customers and more sales – day in, day out!</span>
                                        </div>
                                    </li>
                                </ul>
                                {/* <a href="/#" className="btn btn-bordered mt-4">{this.state.data.btnText}</a> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Service Thumb */}
                            <div className="service-thumb mx-auto pt-4 pt-lg-0">
                                <img src='./img/service.webp' alt="" style={{ marginTop: '40px' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="shape shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                        <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div>
            </section>
        );
    }
}

export default ContentOne;