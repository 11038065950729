import React from 'react';

const Map = () => {
    return (
        <section className="section map-area">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3619.8635101338646!2d67.0646943144776!3d24.86851085106065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33e970c4d0c6b%3A0x1d2237a755e3470!2sManhill%20Advertising%20%26%20Marketing%20(Pvt)%20Ltd.%20Sun%20Builders%26%20Developers!5e0!3m2!1sen!2s!4v1633434468813!5m2!1sen!2s" style={{border: 0}} allowFullScreen width={100} height={100} />
        </section>
    );
};

export default Map;