import React, {useState} from 'react';




const Scrollup = () => {

    const [visible, setVisible] = useState(false)
  
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setVisible(true)
      } 
      else if (scrolled <= 300){
        setVisible(false)
      }
    };
    
    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    };
    
    window.addEventListener('scroll', toggleVisible);
    return (
        <div id="scrollUp" title="Scroll To Top">
            {/* <i className="fas fa-arrow-up" onClick={scrollToTop} /> */}
            <button onClick={scrollToTop} style={{background:'transparent', border:'none',outline:'none'}}><i className="fas fa-arrow-up" style={{color:'white', fontSize:'20px', marginBottom:'-4px'}}></i></button>
        </div>
        
    );
};

export default Scrollup;