import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-2/themeOneFooterSection";

class FooterOne extends Component {
    state = {
        data: {},
        footerList_1: [],
        footerList_2: [],
        footerList_3: [],
        iconList: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    footerList_1: res.data.footerList_1,
                    footerList_2: res.data.footerList_2,
                    footerList_3: res.data.footerList_3,
                    iconList: res.data.iconList
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <footer className="section footer-area">

                <div className="footer-bottom bg-grey">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area  text-center py-4">
                                    © Copyrights 2021 Manhill Advertising All rights reserved.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterOne;