import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json-1/themeOneServiceSection";

class ServiceOne extends Component {
    state = {
        data: {},
        serviceData: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    serviceData: res.data.serviceData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="service" className="section service-area bg-grey ptb_150">
                {/* Shape Top */}
                <div className="shape shape-top">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                    <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                        c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                        c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 style={{textTransform:'capitalize'}}>Our top Services</h2>
                                {/* <p className="d-none d-sm-block mt-4">We provide State of the art services to our valuable clients.<br/>
Lets have a very brief look into the specifics.</p> */}
                                {/* <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.state.serviceData.map((item, idx) => {
                            // return(
                            //     <div key={`so_${idx}`} className="col-12 col-md-6 col-lg-4">
                            //         {/* Single Service */}
                            //         <div className="single-service p-4">
                            //             <span className={item.iconClass} />
                            //             <h3 className="my-3">{item.title}</h3>
                            //             <p>{item.text}</p>
                            //             <a className="service-btn mt-3" href="/#">{item.btnText}</a>
                            //         </div>
                            //     </div>
                            // );
                        })}
                          <div className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service p-4">
                                        <span className="flaticon-web color-3 icon-bg-3" />
                                        <h3 className="my-3">Advertising</h3>
                                        <p>We can run your ads on the most popular TV channels, Newspapers, and Billboard spaces! Manhill’s advertising services are unmatched in effectiveness, simplicity and creativity. We design the perfect advertisements to tell your brand’s story.</p>
                                        {/* <a className="service-btn mt-3" href="/#">{item.btnText}</a> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service p-4">
                                        <span className="flaticon-monitoring color-2 icon-bg-2" />
                                        <h3 className="my-3">Promotion</h3>
                                        <p>We provide the best customer-centric promotional strategies, including kiosks and stalls at strategic locations across the country, contests that create excitement, and merchandise that will elevate your brand to new heights!</p>
                                        {/* <a className="service-btn mt-3" href="/#">{item.btnText}</a> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service p-4">
                                        <span className="flaticon-affiliate color-6 icon-bg-6" />
                                        <h3 className="my-3">Public Relations</h3>
                                        <p>We provide a full range of PR services including writing & distributing press releases, social media promotion, copy writing and blogging as well as executing public outreach programs and improving media relations.</p>
                                        {/* <a className="service-btn mt-3" href="/#">{item.btnText}</a> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service p-4">
                                        <span className="flaticon-smartphone color-4 icon-bg-4" />
                                        <h3 className="my-3">Digital Marketing</h3>
                                        <p>We create highly-targeted and best-in-class digital marketing strategies for all our customers to maximize their reach, leads, and conversions. Our strategies include influencer marketing, SEO, SEM, PPC, and Google & Facebook ads.</p>
                                        {/* <a className="service-btn mt-3" href="/#">{item.btnText}</a> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service p-4">
                                        <span className="flaticon-rocket-launch color-1 icon-bg-1" />
                                        <h3 className="my-3">Direct Marketing</h3>
                                        <p>We orchestrate the best strategies for direct marketing using a combination of traditional and unconventional channels. Our direct marketing strategies include Email Marketing, Telemarketing, Direct Selling, and Text Marketing.</p>
                                        {/* <a className="service-btn mt-3" href="/#">{item.btnText}</a> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    {/* Single Service */}
                                    <div className="single-service p-4">
                                        <span className="flaticon-email color-5 icon-bg-5" />
                                        <h3 className="my-3">Event Management</h3>
                                        <p>We organize various events of all sizes locally and internationally. Our goal is to make each event more memorable than the last! Our network of over 1000 top Pakistani and International brands also enables us to connect our clients to potential partners.</p>
                                        {/* <a className="service-btn mt-3" href="/#">{item.btnText}</a> */}
                                    </div>
                                </div>
                                
                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="shape shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none" fill="#FFFFFF">
                    <path className="shape-fill" d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z" />
                    </svg>
                </div>
            </section>
        );
    }
}

export default ServiceOne;