import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import ThemeSix from "../themes/theme-six";
import About from "../components/InnerPages/About/About";
import Services from "../components/InnerPages/Services/Services";
import Team from "../components/InnerPages/Team/Team";
import Contact from "../components/InnerPages/Contact/Contact";
import Error from "../components/InnerPages/Error/Error";
import Portfolio from "../components/InnerPages/Portfolio/PortfolioMasonry/PortfolioMasonry";
import Credibility from "../components/InnerPages/Credibility/Credibilty";
import Clients from "../components/InnerPages/Clients/Clients";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ThemeSix} />
            <Route exact path="/about" component={About} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/credibility" component={Credibility} />
            <Route exact path="/clients" component={Clients} />
            <Route exact path="/404" component={Error} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;