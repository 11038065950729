import React, { Component } from 'react';

const initData = {
    heading: "We have served many Government Sector Clients",
    headingText_1: "Manhill Advertising is a standard star in the cosmos of advertising practices in Pakistan. We were established as an advertising agency in 1998 to contribute to the realm of innovation.",
    headingText_2: "We are headquartered in Karachi with regional offices locating in Islamabad and Lahore.",
    image: "/img/about.jpg"
}

const data = [
    {
        id: 1,
        spanClass: "counter color-1 fw-7",
        spanClass_1: "color-1 fw-7",
        title: "375",
        text: "Happy Clients"
    },
    {
        id: 2,
        spanClass: "counter color-2 fw-7",
        spanClass_1: "color-2 fw-7",
        title: "1500",
        text: "Projects Completed"
    },
    {
        id: 3,
        spanClass: "counter color-3 fw-7",
        spanClass_1: "color-3 fw-7",
        title: "695",
        text: "Running Projects"
    }
]

class ClientsSection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section about-area ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        {/* <div className="col-12 col-lg-6">
                          
                            <div className="about-thumb text-center">
                                <img src={this.state.initData.image} alt="" />
                            </div>
                        </div> */}
                        <div className="col-12 col-lg-12">
                            {/* About Content */}
                            <div className="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                                <h2 className="mb-3" style={{ textTransform: 'capitalize', fontSize: '35px' }}>{this.state.initData.heading}</h2>
                                <br /> <br />
                                <div className="row">
                                    <div className="col-sm-4">
                                        <ul style={{ listStyle: 'square', fontSize: '18px', lineHeight: '50px' }}>
                                            <li>Benazir Income Support Program</li>
                                            <li>Collectorate of Customs</li>
                                            <li>Culture & Tourism Depatrment</li>
                                            <li>Dawood College of Engineering & Technology </li>
                                            <li>Directorate of Hajj, Sukkur</li>
                                            <li>DPR Balochistan</li>
                                            <li>I.T Department (Govt of Sindh) </li>
                                            <li>Information Department Govt. of Sindh </li>
                                            <li>ISRA University</li>
                                            <li>Khuzdar  University of Engineering </li>
                                            <li>Ministry of Human Rights Sindh </li>
                                            <li>Ministry of  Zakat & Religious Affairs </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-4">
                                        <ul style={{ fontSize: '18px', lineHeight: '50px' }}>
                                            <li>Ministry of Home (Sindh)</li>
                                            <li>Ministry of Kachi Abadees Ministry</li>
                                            <li>National Program For Family Planning </li>
                                            <li>Nuclear Institute of Agriculture</li>
                                            <li>Pakistan Agriculture Research Council </li>
                                            <li>Pakistan Bait-ul-Mal </li>
                                            <li>Pakistan Post Hyderabad  </li>
                                            <li>Pakistan Post Quetta  </li>
                                            <li>Pakistan Post Sukkur  </li>
                                            <li>Pakistan Railway  </li>
                                            <li>PARCTTI-Tandojam  </li>
                                            <li>PCRW Tandojam</li>
                                            <li>PWD Quetta</li>
                                            <li>Quaid-e-Awam University </li>
                                            <li>Regional Tax Office Hyderabad </li>


                                        </ul>
                                    </div>
                                    <div className="col-sm-4">
                                        <ul style={{ fontSize: '18px', lineHeight: '50px' }}>
                                            <li>Regional Tax Office Sukkur </li>
                                            <li>Sindh Agriculture University Tandojam  </li>
                                            <li>Social Welfare Department </li>
                                            <li>State Bank Of Pakistan (Quetta)  </li>
                                            <li>SZABAC </li>
                                            <li>UNDP</li>
                                            <li>University Law College Quetta</li>
                                            <li>Controller of Military Accounts</li>
                                            <li>Directorate of Hajj (Govt. of Pakistan)</li>
                                            <li>Establishment Division</li>
                                            <li>Immigration System</li>
                                            <li>Military Accountant General</li>
                                            <li>Military Estates Office</li>
                                            <li>Ministry of Defence Production (Govt. of Pakistan)</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* <p>{this.state.initData.headingText_1}</p><br />
                                <p>{this.state.initData.headingText_2}</p> */}
                                {/* Counter Area */}
                                <div className="couter-area mt-5">
                                    {/* Single Counter */}
                                    {this.state.data.map((item, idx) => {
                                        // return(
                                        //     <div className="single-counter d-block d-md-inline-block m-4 m-md-2">
                                        //         <div className="counter-inner">
                                        //             {/* Counter Item */}
                                        //             <div className="counter-item d-inline-block mb-3">
                                        //                 <span className={item.spanClass}>{item.title}</span><span className={item.spanClass_1}>+</span>
                                        //             </div>
                                        //         </div>
                                        //         <h4 className="text-uppercase fw-7">{item.text}</h4>
                                        //     </div>
                                        // );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ClientsSection;