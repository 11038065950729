import React, { Component } from 'react';

import ScrollupSection from '../../Scrollup/Scrollup';
import Header from '../../Header/HeaderOne';
import BreadcrumbSection from '../../Breadcrumb/BreadcrumbOne';
import CredibilitySection from './CredibilitySection';
import FooterSection from '../../Footer/FooterOne';
import ModalSearchSection from '../../Modal/ModalSearch';
import ModalMenuSection from '../../Modal/ModalMenu';
import ContactSection from '../../Contact/ContactOne';
import CtaSection from '../../Cta/Cta';

class Credibility extends Component {
    render() {
        return (
            <div>
                <ScrollupSection />
                <div className="main overflow-hidden">
                    <Header imageData={"/img/logo-white.png"} />
                    <BreadcrumbSection heading={"Credibility"} home={"Home"} page={"Pages"} title={"Credibility"} />
                    <CredibilitySection />
                    {/* <GoalSection />
                    <TeamSection /> */}
                    <ContactSection />
                    <CtaSection />
                    <FooterSection />
                    <ModalSearchSection />
                    <ModalMenuSection />
                </div>
            </div>
        );
    }
}

export default Credibility;