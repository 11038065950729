import React, { Component } from 'react';

const initData = {
    heading: "Memberships and accreditations",
    subheading: "Manhill Advertising has Acquired the Following Membership and Accreditations.",
    headingText_1: "Accredited with all Pakistan Newspapers Society (APNS)",
    headingText_2: "Registered with Press Information Department (PID)",
    headingText_3: "Registered with Pakistan Broadcasting Association (PBA)",
    headingText_4: "Registered with Pakistan Advertising Association (PAA)",
    image: "/img/accreditations.jpg"
}

const data = [
    {
        id: 1,
        spanClass: "counter color-1 fw-7",
        spanClass_1: "color-1 fw-7",
        title: "375",
        text: "Happy Clients"
    },
    {
        id: 2,
        spanClass: "counter color-2 fw-7",
        spanClass_1: "color-2 fw-7",
        title: "1500",
        text: "Projects Completed"
    },
    {
        id: 3,
        spanClass: "counter color-3 fw-7",
        spanClass_1: "color-3 fw-7",
        title: "695",
        text: "Running Projects"
    }
]

class CredibilitySection extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section about-area ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            {/* About Thumb */}
                            <div className="about-thumb text-center">
                                <img src={this.state.initData.image} alt="" style={{ marginTop: '-50px' }} />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* About Content */}
                            <div className="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                                <h2 className="mb-3" style={{ textTransform: 'capitalize' }}>{this.state.initData.heading}</h2>
                                <h5 className="mb-3" style={{ textTransform: 'capitalize' }}>{this.state.initData.subheading}</h5>
                                <p>{this.state.initData.headingText_1}</p><br />
                                <p>{this.state.initData.headingText_2}</p><br />
                                <p>{this.state.initData.headingText_3}</p><br />
                                <p>{this.state.initData.headingText_4}</p>
                                {/* Counter Area */}
                                <div className="couter-area mt-5">
                                    {/* Single Counter */}
                                    {this.state.data.map((item, idx) => {
                                        // return(
                                        //     <div className="single-counter d-block d-md-inline-block m-4 m-md-2">
                                        //         <div className="counter-inner">
                                        //             {/* Counter Item */}
                                        //             <div className="counter-item d-inline-block mb-3">
                                        //                 <span className={item.spanClass}>{item.title}</span><span className={item.spanClass_1}>+</span>
                                        //             </div>
                                        //         </div>
                                        //         <h4 className="text-uppercase fw-7">{item.text}</h4>
                                        //     </div>
                                        // );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>


                    <br /><br /><br />
                    <div className="row justify-content-between align-items-center">

                        <div className="col-12 col-lg-6">
                            <h3 style={{ textAlign: 'center' }}>ACCREDITATION LETTERS OF PBA & APNS</h3>
                            <br /><br />
                            <div className="row">
                                <div className="col-sm-6">
                                    <img src='./img/accrediations/Picture1.png' alt="" />
                                </div>
                                <div className="col-sm-6">
                                    <img src='./img/accrediations/Picture2.png' alt="" />
                                </div>
                            </div>
                        </div>



                        <div className="col-12 col-lg-6">
                            <h3 style={{ textAlign: 'center' }}>MEMBERSHIP CERTIFICATE OF PAA</h3>

                            <img src='./img/accrediations/Picture3.png' alt="" />
                        </div>
                    </div>

                </div>
            </section>
        );
    }
}

export default CredibilitySection;