import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/digimax-json/themeOneContentSection";

class ContentTwo extends Component {
    state = {
        data: {},
        contentData_1: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    contentData_1: res.data.contentData_1
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="section content-area ptb_150">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-6">
                            {/* Profile Circle Wrapper */}
                            <div className="profile-circle-wrapper circle-animation d-none d-sm-block">
                                {/* Profile Inner */}
                                <div className="profile-inner">
                                    {/* Profile Circle */}
                                    <div className="profile-circle circle-lg">
                                        <span className="profile-icon icon-1">
                                            <img className="icon-1-img" src={this.state.data.profileIcon_1} alt="" />
                                        </span>
                                        <span className="profile-icon icon-2">
                                            <img className="icon-2-img" src={this.state.data.profileIcon_2} alt="" />
                                        </span>
                                        <span className="profile-icon icon-3">
                                            <img className="icon-3-img" src={this.state.data.profileIcon_1} alt="" />
                                        </span>
                                        <span className="profile-icon icon-4">
                                            <img className="icon-4-img" src={this.state.data.profileIcon_2} alt="" />
                                        </span>
                                    </div>
                                    {/* Profile Circle */}
                                    <div className="profile-circle circle-md">
                                        <span className="profile-icon icon-5">
                                            <img className="icon-5-img" src={this.state.data.profileIcon_3} alt="" />
                                        </span>
                                        <span className="profile-icon icon-6">
                                            <img className="icon-6-img" src={this.state.data.profileIcon_3} alt="" />
                                        </span>
                                        <span className="profile-icon icon-7">
                                            <img className="icon-7-img" src={this.state.data.profileIcon_3} alt="" />
                                        </span>
                                    </div>
                                    {/* Profile Circle */}
                                    <div className="profile-circle circle-sm">
                                        <span className="profile-icon icon-8">
                                            <img className="icon-8-img" src={this.state.data.profileIcon_4} alt="" />
                                        </span>
                                        <span className="profile-icon icon-9">
                                            <img className="icon-9-img" src={this.state.data.profileIcon_4} alt="" />
                                        </span>
                                    </div>
                                </div>
                                <img className="folder-img" src='./img/smart.webp' alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Content Inner */}
                            <div className="content-inner text-center pt-sm-4 pt-lg-0 mt-sm-5 mt-lg-0">
                                {/* Section Heading */}
                                <div className="section-heading text-center mb-3">
                                    <h2 style={{textTransform:'capitalize',fontSize:'30px'}}>{this.state.data.heading_1}<br /> {this.state.data.heading_1_span}</h2>
                                    {/* <p className="d-none d-sm-block mt-4">{this.state.data.headingText_1}</p>
                                    <p className="d-block d-sm-none mt-4">{this.state.data.headingText_2}</p> */}
                                </div>
                                {/* Content List */}
                                <ul className="content-list text-left">
                                    {/* Single Content List */}
                                    {this.state.contentData_1.map((item, idx) => {
                                        // return(
                                        //     <li key={`ct_${idx}`} className="single-content-list media py-2">
                                        //         <div className="content-icon pr-4">
                                        //             <span className="color-2"><i className={item.iconClass} /></span>
                                        //         </div>
                                        //         <div className="content-text media-body">
                                        //             <span><b>{item.title}</b><br />{item.text}</span>
                                        //         </div>
                                        //     </li>
                                        // );
                                    })}
                                      <li className="single-content-list media py-2">
                                                <div className="content-icon pr-4">
                                                    <span className="color-2"><i className="fas fa-angle-double-right" /></span>
                                                </div>
                                                <div className="content-text media-body">
                                                    <span><b style={{fontWeight:'bold', color:'black'}}>Become a Leader in Your Industry</b><br />We wouldn’t be the best if we didn’t know how to create leaders. With Manhill Advertising’s decades of experience working across multiple industries, you can be sure that we’ve got the right strategy to help you establish yourself as a Leader in your industry!</span>
                                                </div>
                                            </li>
                                            <li className="single-content-list media py-2">
                                                <div className="content-icon pr-4">
                                                    <span className="color-2"><i className="fas fa-angle-double-right" /></span>
                                                </div>
                                                <div className="content-text media-body">
                                                    <span><b style={{fontWeight:'bold', color:'black'}}>Revitalize Your PR Efforts</b><br />We build brands by shaping both public opinion and media coverage. We know how to get your brand attention by pitching news-worthy stories to the right people at the right time!</span>
                                                </div>
                                            </li>
                                            <li className="single-content-list media py-2">
                                                <div className="content-icon pr-4">
                                                    <span className="color-2"><i className="fas fa-angle-double-right" /></span>
                                                </div>
                                                <div className="content-text media-body">
                                                    <span><b style={{fontWeight:'bold', color:'black'}}>Host Exclusive Parties & Events</b><br />Whether you’re looking to reward your employees, attract potential investors, or engage with clients, we can help you organize and manage all your events!</span>
                                                </div>
                                            </li>
                                </ul>
                                {/* <a href="/#" className="btn btn-bordered mt-4">{this.state.data.btnText}</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContentTwo;