import React, { Component } from 'react';

const initData = {
    heading: "Vision Leading to Mission",
    headingText_1: "To reinvigorate the existing patterns of advertising.",
    headingText_2: "To create with creative magic and logic.",
    headingText_3: "Develop and innovate.",
    headingText_4: "Deliver beyond perfection.",
    image: "/img/vision.jpg",
    btnText: "Read More"
}

class Goal extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section className="section our-goal ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-6">
                            {/* Goal Content */}
                            <div className="goal-content section-heading text-center text-lg-left pr-md-4 mb-0">
                                <h2 className="mb-3" style={{textTransform:'capitalize'}}>{this.state.data.heading}</h2>
                                <p style={{fontSize:'18px'}}><b>VISION: </b>{this.state.data.headingText_1}</p><br />
                                <p style={{fontSize:'18px'}}><b>APPROACH: </b>{this.state.data.headingText_2}</p><br />
                                <p style={{fontSize:'18px'}}><b>MOTTO: </b>{this.state.data.headingText_3}</p><br />
                                <p style={{fontSize:'18px'}}><b>MISSION: </b>{this.state.data.headingText_4}</p>
                                {/* <a href="/#" className="btn btn-bordered mt-4">{this.state.data.btnText}</a> */}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            {/* Goal Thumb */}
                            <div className="goal-thumb mt-5 mt-lg-0">
                                <img src={this.state.data.image} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Goal;