import React, { Component } from 'react';

const initData = {
    heading: "WHO WE ARE",
    headingText_1: "Manhill Advertising helps the world's most remarkable companies improve their impact and establish themselves as a brand. We are a force to be reckoned with, operating in PR, advertising, digital marketing and more. With extensive experience across all channels of communications - from print to video production - we offer our clients an unmatched level of expertise. And because we're so committed to your success, we'll work with you for as long as it takes; no project is ever too big or too small.",
    headingText_2: "We are headquartered in Karachi with regional offices locating in Islamabad and Lahore.",
    image: "/img/about.jpg"
}

const data = [
    {
        id: 1,
        spanClass: "counter color-1 fw-7",
        spanClass_1: "color-1 fw-7",
        title: "400",
        text: "Happy Clients"
    },
    {
        id: 2,
        spanClass: "counter color-2 fw-7",
        spanClass_1: "color-2 fw-7",
        title: "1600",
        text: "Projects Completed"
    },
    {
        id: 3,
        spanClass: "counter color-3 fw-7",
        spanClass_1: "color-3 fw-7",
        title: "800",
        text: "Running Projects"
    }
]

class AboutUs extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount() {
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="section about-area ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        {/* <div className="col-12 col-lg-6">
                          
                            <div className="about-thumb text-center">
                                <img src={this.state.initData.image} alt="" />
                            </div>
                        </div> */}
                        <div className="col-12 col-lg-12">
                            {/* About Content */}
                            <div className="about-content section-heading text-center text-lg-left pl-md-4 mt-5 mt-lg-0 mb-0">
                                <h2 className="mb-3" style={{ textTransform: 'capitalize' }}>{this.state.initData.heading}</h2>
                                <p>{this.state.initData.headingText_1}</p><br />
                                {/* <p>{this.state.initData.headingText_2}</p> */}
                                {/* Counter Area */}
                                <div className="couter-area mt-5">
                                    {/* Single Counter */}
                                    {this.state.data.map((item, idx) => {
                                        return (
                                            <div key={idx} className="single-counter d-block d-md-inline-block m-4 m-md-2 margin-r-100" >
                                                <div className="counter-inner">
                                                    {/* Counter Item */}
                                                    <div className="counter-item d-inline-block mb-3">
                                                        <span className={item.spanClass}>{item.title}</span><span className={item.spanClass_1}>+</span>
                                                    </div>
                                                </div>
                                                <h4 className="text-uppercase fw-7">{item.text}</h4>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutUs;