import React, { Component } from 'react';

class BreadcrumbOne extends Component {
    render() {
        return (
            <section className="section breadcrumb-area overlay-dark d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Breamcrumb Content */}
                        <div className="breadcrumb-content d-flex flex-column align-items-center text-center">
                            <h2 className="text-white text-uppercase mb-3">{this.props.heading}</h2>
                          
                        </div>
                    </div>
                </div>
            </div>
        </section>
        );
    }
}

export default BreadcrumbOne;